import CapacityWidget from "#Components/DynamicForm/CapacityWidget";
import DnsWidget from "#Components/DynamicForm/DnsWidget";
import ExplainButtonWidget from "#Components/DynamicForm/ExplainButtonWidget";
import ExtraDnsWidget from "#Components/DynamicForm/ExtraDnsWidget";
import { getServiceHosts } from "#Components/DynamicForm/helpers";
import HostWidget from "#Components/DynamicForm/HostWidget";
import { getServiceSchema } from "#Constants/Endpoints";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import axios from "axios";
import { flatten, unflatten } from "flat";
import _ from "lodash";
import React, { createRef, PureComponent } from "react";
import { withAlert } from "react-alert";
import { Spinner } from "reactstrap";

const widgets = {
  CapacityWidget,
  DnsWidget,
  ExtraDnsWidget,
  HostWidget,
  ExplainButtonWidget,
};

const fetchClient = axios.create();

const applyPattern = ({
  pattern,
  sourceObj,
  targetObj,
  valueTransformer = (v) => v,
}) => {
  for (let key in targetObj) {
    let value = targetObj[key];
    if (value && typeof value === "string") {
      let matches = value.match(pattern);
      while (matches) {
        matches.forEach((match) => {
          let sourceObjKey = match.substring(1, match.length - 1);
          value = value.replace(match, sourceObj[sourceObjKey]);
        });
        targetObj[key] = valueTransformer(value);
        matches = targetObj[key].match(pattern);
      }
    }
  }
};

const getAllObjects = (obj, objArr = []) => {
  objArr.push(obj);
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] === "object") {
        getAllObjects(obj[property], objArr);
      }
    }
  }
  return objArr;
};

export const CLOUD_ONLY = {
  hideFields: [
    "config",
    "cluster.capacity",
    "cluster.nameserver",
    "cluster.dnsName",
    "cluster.extraDnsName",
    "cluster.extraDnsApps",
    "cluster.explainButton",
  ],
};

export const CAPACITY_ONLY = {
  hideFields: [
    "config",
    "cluster.nameserver",
    "cluster.location",
    "cluster.name",
    "cluster.dnsName",
    "cluster.extraDnsName",
    "cluster.extraDnsApps",
    "cluster.explainButton",
  ],
  overrideOptions: [
    {
      path: "cluster",
      options: {
        title: "",
        description: "",
        classNames: "hide-select",
      },
    },
  ],
};

export const DOMAINS_ONLY = {
  hideFields: [
    "config",
    "cluster.capacity",
    "cluster.nameserver",
    "cluster.location",
    "cluster.name",
  ],
  overrideOptions: [
    {
      path: "cluster",
      options: {
        title: "",
        description: "",
        classNames: "hide-select",
      },
    },
  ],
};

class DynamicForm extends PureComponent {
  state = {
    loading: true,
    message: "",
    schema: {},
    uiSchema: {},
    formData: {},
    errors: [],
  };

  componentDidMount() {
    this.checkCloudService();
    this.formRef = createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      ticketDetails,
      extraErrors,
      onSchemaChange = (s, u) => {},
      onFormDataChange = (f, e) => {},
    } = this.props;
    const { schema, uiSchema } = this.state;
    if (
      !_.isEqual(ticketDetails.extraAttr, prevProps.ticketDetails.extraAttr)
    ) {
      this.checkCloudService();
    }
    if (
      !_.isEqual(
        ticketDetails.jsonSchema?.formData,
        prevProps.ticketDetails.jsonSchema?.formData,
      )
    ) {
      this.setState({ formData: ticketDetails.jsonSchema?.formData });
    }
    if (!_.isEqual(extraErrors, prevProps.extraErrors)) {
      const { formData, errors } = this.formRef.current.state;
      this.formRef.current.setState({ errorSchema: extraErrors });
      onFormDataChange(formData, errors);
    }
    if (
      !_.isEqual(schema, prevState.schema) ||
      !_.isEqual(uiSchema, prevState.uiSchema)
    ) {
      onSchemaChange(schema, uiSchema);
    }
  }

  errorHandler = (error) => {
    this.props.alert.error(error.message);
  };

  processSchema = (schema) => {
    const asIsPattern = /\{[^{}]+\}/gi;
    const noSpecialCharsPattern = /\[[^\[\]]+\]/gi;
    const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
    const flattenedClvObj = flatten(clvObj);
    const flattenedSchema = flatten(schema);
    applyPattern({
      pattern: asIsPattern,
      sourceObj: flattenedClvObj,
      targetObj: flattenedSchema,
    });
    applyPattern({
      pattern: noSpecialCharsPattern,
      sourceObj: flattenedClvObj,
      targetObj: flattenedSchema,
      valueTransformer: (v) => v.replace(/[^A-Z0-9]+/gi, "_"),
    });
    return unflatten(flattenedSchema);
  };

  checkCloudService = () => {
    const { org } = JSON.parse(sessionStorage.getItem("Clouve.object"));
    const { ticketDetails } = this.props;
    const { jsonSchema = {}, extraAttr = {} } = ticketDetails;
    const { schema, uiSchema, formData } = jsonSchema;
    if (schema && uiSchema && formData) {
      this.setState({
        schema: this.processSchema(schema),
        uiSchema,
        formData,
        loading: false,
      });
    } else {
      this.setState({ loading: true });
      fetchClient(
        `${getServiceSchema}/${org.orgId}/${extraAttr.selfServiceType}`,
      )
        .then(({ data }) => data)
        .then(({ schema, uiSchema }) => {
          this.setState({
            schema: this.processSchema(schema),
            uiSchema,
            formData,
            loading: false,
          });
        })
        .catch((error) => {
          this.errorHandler(error);
        });
    }
  };

  onFocus = (fieldId, fieldValue) => {
    const { onFormDataFocus = (x, y) => {} } = this.props;
    onFormDataFocus(fieldId, fieldValue);
  };

  onBlur = (fieldId, fieldValue) => {
    const { onFormDataBlur = () => {} } = this.props;
    onFormDataBlur(fieldId, fieldValue);
  };

  onChange = (jsonSchema, fieldId) => {
    const { onFormDataChange = (f, e) => {}, ticketDetails } = this.props;
    const { formData: prevFormData = {}, errors: prevErrors = [] } = this.state;
    const { formData = {}, errors = [] } = jsonSchema;
    if (!_.isEqual(formData, prevFormData || !_.isEqual(errors, prevErrors))) {
      const { cluster: prevCluster = {} } = prevFormData;
      const { cluster = {} } = formData;
      const { extraDnsName: prevExtraDnsName } = prevCluster;
      const { extraDnsName } = cluster;
      if (extraDnsName !== prevExtraDnsName) {
        cluster["extraDnsApps"] = [];
        if (extraDnsName) {
          const { attributes: serviceAttributes = {} } = ticketDetails;
          const { serviceHosts } = getServiceHosts(
            extraDnsName,
            serviceAttributes,
          );
          if (serviceHosts.length > 1) {
            cluster["extraDnsApps"] = serviceHosts;
          }
        }
      }
      this.setState({ formData, errors }, () => {
        onFormDataChange(formData, errors, fieldId);
      });
    }
  };

  render() {
    const {
      ticketDetails,
      extraErrors,
      disabled = false,
      readonly = false,
      onSubmit = () => {},
      hideFields = [],
      overrideOptions = [],
    } = this.props;
    const { loading, schema, uiSchema, formData } = this.state;

    let uiSchemaClone = _.cloneDeep(uiSchema);
    if (hideFields && hideFields.length) {
      hideFields.forEach((path) => {
        _.set(uiSchemaClone, `${path}["ui:widget"]`, "hidden");
      });
    }
    if (overrideOptions && overrideOptions.length) {
      overrideOptions.forEach(({ path, options }) => {
        _.set(uiSchemaClone, `${path}["ui:options"]`, options);
      });
    }

    const onError = (errors) =>
      console.log("I have", errors.length, "errors to fix");

    if (loading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    return (
      <Form
        ref={this.formRef}
        disabled={disabled}
        readonly={readonly}
        schema={schema}
        validator={validator}
        extraErrors={extraErrors}
        widgets={widgets}
        uiSchema={uiSchemaClone}
        formData={formData}
        formContext={{ ticketDetails, formData }}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.onChange}
        liveValidate
        showErrorList={false}
        onSubmit={onSubmit}
        onError={onError}
      />
    );
  }
}

export default withAlert()(DynamicForm);
