import { InfoColumn, InfoTable } from "#Components/InfoTable";
import { fetchKubeConfigs } from "#Graphql/query";
import { downloadContentAsFile } from "#hoc/util";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card } from "reactstrap";

const dateFormat = (value) => {
  return moment.utc(value).local().format("MMM DD, YYYY h:mm A");
};

const ClusterList = (props) => {
  const [kubeConfigs, setKubeConfigs] = useState([]);
  useEffect(() => {
    (async () => {
      const { kubeConfigs } = await fetchKubeConfigs();
      setKubeConfigs(kubeConfigs);
    })();
  }, []);

  const renderIcon = (row, index, column, id) => {
    return (
      <Button
        color="link"
        onClick={() => {
          downloadContentAsFile({
            content: row.yaml,
            fileName: row.file.split("/").pop(),
          });
        }}
      >
        <i className="fs-3 fa-solid fa-file-arrow-down" />
      </Button>
    );
  };

  return (
    <Card className="content-wrapper">
      <div>
        <InfoTable data={kubeConfigs} pagination={kubeConfigs.length > 10}>
          <InfoColumn cell={renderIcon} width="32px" center />
          <InfoColumn
            selector={(row) => row.file}
            format={(row) => row.file.split("/").pop()}
            sortable
            center
            width="200px"
          >
            File Name
          </InfoColumn>
          <InfoColumn
            selector={(row) => row.timeCreated}
            format={(row) => `${dateFormat(row.timeCreated)}`}
            sortable
            center
            width="200px"
          >
            Date Created
          </InfoColumn>
        </InfoTable>
      </div>
    </Card>
  );
};

export default ClusterList;
