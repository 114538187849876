import { getServiceHosts } from "#Components/DynamicForm/helpers";
import { TabbedPane } from "#Components/TabNav";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const getDomainIPs = async (domain) => {
  try {
    // Use DNS resolution via WebRTC
    const ipResponse = await fetch(`https://dns.google/resolve?name=${domain}`);
    const dnsResult = await ipResponse.json();

    // Extract IPv4 addresses from the DNS response
    return dnsResult.Answer?.filter(({ type }) => type === 1).map(
      ({ data }) => data,
    );
  } catch (error) {
    console.error("IP lookup failed:", error);
    return [];
  }
};

const ExplainModal = ({
  toggle,
  showPopup,
  dnsAlias,
  extraDnsName,
  clusterDns,
  domainIPs,
}) => {
  const renderExplainCname = () => (
    <>
      <p>
        To add a CNAME record to your DNS service provider, you will need to
        follow a process that can vary slightly depending on the specific DNS
        provider you're using. Here is a general outline of the steps involved:
      </p>

      <ol>
        <li>
          <p>
            <strong>Log in to your DNS provider:</strong> Access your DNS
            provider's website and log in to your account. This would be the
            place where you have your domain registered, like GoDaddy, Bluehost,
            Cloudflare, or Namecheap.
          </p>
        </li>
        <li>
          <p>
            <strong>Access DNS Management:</strong> Look for the DNS settings or
            DNS management page. This is often found within the domain
            management or advanced settings area.
          </p>
        </li>
        <li>
          <p>
            <strong>Add a new DNS record:</strong> Look for an option to "Add
            Record", "Create Record", or similar. Click on it.
          </p>
        </li>
        <li>
          <p>
            <strong>Specify the CNAME record:</strong> You'll need to fill in at
            least three fields:
          </p>
          <ul className="mb-3">
            <li>
              <strong>Type:</strong> This should be set to "CNAME".
            </li>
            <li>
              <strong>Host or Alias:</strong> Here, you'd enter `
              <strong>{dnsAlias}</strong>`. Some providers might require you to
              put the full domain `<strong>{extraDnsName}</strong>`.
            </li>
            <li>
              <strong>Value, Points to, or Target:</strong> Here, you'd enter `
              <strong>{clusterDns}</strong>`.
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>Save the record:</strong> After you've filled in the
            necessary information, look for a button to save or create the
            record.
          </p>
        </li>
        <li>
          <p>
            <strong>Propagation time:</strong> After you've added the CNAME
            record, it may take up to 48 hours for the changes to propagate
            across the internet, although it usually happens much faster.
          </p>
        </li>
      </ol>

      <p>
        Remember, before you make changes to your DNS settings, it's a good idea
        to write down the current settings, just in case you need to revert
        back.
      </p>

      <p>
        This is a general guide and the steps might differ slightly depending on
        your DNS provider. If you have any trouble, it's a good idea to consult
        the specific help documentation or support services of your DNS
        provider.
      </p>
    </>
  );
  const renderExplainIPv4 = () => (
    <>
      <p>
        To add an A record to your DNS service provider, you will need to follow
        a process that can vary slightly depending on the specific DNS provider
        you're using. Here is a general outline of the steps involved:
      </p>

      <ol>
        <li>
          <p>
            <strong>Log in to your DNS provider:</strong> Access your DNS
            provider's website and log in to your account. This is typically the
            service where your domain is registered, such as GoDaddy, Bluehost,
            Cloudflare, or Namecheap.
          </p>
        </li>
        <li>
          <p>
            <strong>Access DNS Management:</strong> Navigate to the DNS settings
            or DNS management page. This is often located within the domain
            management or advanced settings section.
          </p>
        </li>
        <li>
          <p>
            <strong>Add a new DNS record:</strong> Look for an option labeled
            "Add Record", "Create Record", or something similar, and click on
            it.
          </p>
        </li>
        <li>
          <p>
            <strong>Specify the A record details:</strong> Fill in the necessary
            fields for the A record:
          </p>
          <ul className="mb-3">
            <li>
              <strong>Type:</strong> Set this to "A" (for an A record).
            </li>
            <li>
              <strong>Host or Name:</strong>{" "}
              {dnsAlias ? (
                <>
                  Enter`<strong>{dnsAlias}</strong>`. Some providers may require
                  the full domain, such as `<strong>{extraDnsName}</strong>`.
                </>
              ) : (
                <>
                  For a naked domain, leave this field blank or enter the{" "}
                  <strong>"@"</strong> symbol. Many DNS providers use{" "}
                  <strong>"@"</strong> to represent the root domain.
                </>
              )}
            </li>
            <li>
              <strong>Value or Points to:</strong> Enter any of the following
              IPv4 addresses you want the A record to resolve to:
              <ul>
                {domainIPs.map((ip) => (
                  <li key={ip}>
                    <strong>{ip}</strong>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>Save the record:</strong> After entering the required
            information, click the save or create button to finalize the
            addition of the A record.
          </p>
        </li>
        <li>
          <p>
            <strong>Propagation time:</strong> Once added, the new A record may
            take up to 48 hours to propagate across the internet. However, in
            most cases, it happens much faster.
          </p>
        </li>
      </ol>

      <p>
        Remember, before you make changes to your DNS settings, it's a good idea
        to write down the current settings, just in case you need to revert
        back.
      </p>

      <p>
        This is a general guide and the steps might differ slightly depending on
        your DNS provider. If you have any trouble, it's a good idea to consult
        the specific help documentation or support services of your DNS
        provider.
      </p>
    </>
  );
  return (
    <Modal
      placement="bottom"
      isOpen={showPopup}
      target="explainBtn"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>How to add a DNS Record?</ModalHeader>
      <ModalBody>
        <TabbedPane
          tabs={[
            {
              tabId: "cname",
              tabLabel: (
                <>
                  Adding a <strong>CNAME</strong> Record
                </>
              ),
              tabComponent: renderExplainCname(),
            },
            {
              tabId: "ipv4",
              tabLabel: (
                <>
                  Adding an <strong>A Record</strong> (IPv4)
                </>
              ),
              tabComponent: renderExplainIPv4(),
            },
          ]}
          width="full"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ExplainButton = ({
  serviceAttributes = {},
  extraDnsName,
  clusterDns,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [domainIPs, setDomainIPs] = useState([]);
  useEffect(() => {
    getDomainIPs(clusterDns).then(setDomainIPs);
  }, [clusterDns]);
  const toggle = () => {
    setShowPopup(!showPopup);
  };
  const { dnsParts, dnsAlias } = getServiceHosts(
    extraDnsName,
    serviceAttributes,
  );
  const renderExplainButton =
    extraDnsName &&
    dnsParts.length > 1 &&
    dnsParts[0] &&
    dnsParts[dnsParts.length - 1];
  return (
    <div className="p-2">
      {renderExplainButton ? (
        <>
          {" "}
          <Alert id="explainBtn" color="warning" className="mt-0 mb-0 pb-0">
            <div>
              Make sure to configure <strong>ANY</strong> of the following DNS
              records for the above specified host(s) within your DNS service
              provider:
              <ul>
                <li>
                  <strong>CNAME:</strong> Pointing to{" "}
                  <strong>{clusterDns}</strong>
                </li>
                <li>
                  <strong>A (IPv4):</strong> Pointing to the following IP
                  addresses:
                  <ul>
                    {domainIPs.map((ip) => (
                      <li key={ip}>
                        <strong>{ip}</strong>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
            <div className="text-end mt-3">
              <Button color="link" size="sm" onClick={toggle}>
                Explain How?
              </Button>
            </div>
          </Alert>
          <ExplainModal
            toggle={toggle}
            showPopup={showPopup}
            dnsAlias={dnsAlias}
            extraDnsName={extraDnsName}
            clusterDns={clusterDns}
            domainIPs={domainIPs}
          />
        </>
      ) : null}
    </div>
  );
};

export default ({ formContext }) => {
  const { ticketDetails = {}, formData = {} } = formContext;
  const { attributes = {} } = ticketDetails;
  const { cluster = {} } = formData;
  const {
    cloudServiceProvider: csp = "",
    name = "",
    nameserver = "",
    extraDnsName = "",
    extraDnsApps = [],
  } = cluster;
  return (
    <ExplainButton
      serviceAttributes={attributes}
      extraDnsName={extraDnsName}
      extraDnsApps={extraDnsApps}
      clusterDns={`${csp.toLowerCase()}-${name}.${nameserver}`}
    />
  );
};
