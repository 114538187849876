import { TICKETHISTORY } from "#Graphql";
import { submitAddComment } from "#Graphql/mutate";
import { client } from "#hoc/config";
import { getUserIcon } from "#hoc/util";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import {
  Button,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";

const fetchTicketHistory = async (ticketDetails) => {
  try {
    const response = await client.query({
      query: TICKETHISTORY,
      variables: { ticketId: ticketDetails.id },
      fetchPolicy: "network-only",
    });
    if (response.data.ticketHistoryById) {
      return response.data.ticketHistoryById;
    }
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const TicketHistory = ({ historyDetails = [] }) => {
  if (historyDetails.length > 0) {
    return historyDetails.map((item, idx) => {
      return (
        <div className="history-block" key={idx}>
          <span>
            <i
              className={getUserIcon(item?.actionByUser?.organizationRole)}
              style={{ fontSize: "1rem" }}
            />
          </span>
          <span className="action-performed">{item.text}</span>
          <span className="date-time">
            {moment.utc(item.createdAt).local().format("MMM DD, YYYY h:mm A")}
          </span>
        </div>
      );
    });
  } else {
    return <span>No Data Found....</span>;
  }
};

export const TicketChat = ({ comments = [] }) => {
  return (
    <div className="comments-block">
      {comments.length > 0 ? (
        comments.map((item, idx) => {
          return (
            <div className="pb-2" key={idx}>
              <div className="comt-block-header">
                <span>
                  <i className={getUserIcon(item.user.organizationRole)} />{" "}
                  {item.user.name}
                </span>
                <span>
                  {moment
                    .utc(item.commentDate)
                    .local()
                    .format("MMM DD, YYYY h:mm A")}
                </span>
              </div>
              <div className="message-block p-3 mt-1 border-bottom border-secondary">
                <Markdown>{item.message}</Markdown>
              </div>
            </div>
          );
        })
      ) : (
        <div className="gray-medium">No chat history found!</div>
      )}
    </div>
  );
};

export const TicketChatHistory = ({
  ticketDetails,
  onSuccess = (x) => {},
  onFailure = (x) => {},
}) => {
  const { comments = [] } = ticketDetails;
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [historyDetails, setHistoryDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async function fetchData() {
      const historyDetails = await fetchTicketHistory(ticketDetails);
      setHistoryDetails(historyDetails);
    })();
  }, []);

  const handleAddComment = async () => {
    try {
      setIsLoading(true);
      const { addComment } = await submitAddComment({
        ticketId: ticketDetails.id,
        comment: message,
      });
      if (addComment && addComment.code === 201) {
        onSuccess(addComment.message);
      } else {
        onFailure(addComment);
      }
    } catch (err) {
      onFailure(err);
    }
    setIsLoading(false);
    setMessage("");
  };
  const isDeployment = ticketDetails.type === "DEPLOYMENT";
  return (
    <div className="ticket-chat-history tab-nav">
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={isHistoryOpen ? "" : "active"}
            onClick={() => {
              setIsHistoryOpen(false);
            }}
          >
            CHAT
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={isHistoryOpen ? "active" : ""}
            onClick={() => {
              setIsHistoryOpen(true);
            }}
          >
            TICKET HISTORY
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={isHistoryOpen ? "history" : "chat"}
        className="comments-detail"
      >
        <TabPane tabId="chat">
          <Label>Previous Messages</Label>
          <TicketChat
            comments={comments.map((item) => ({
              user: {
                id: item.commented_by_id,
                name: item.commented_by_name,
                email: item.comment_by_email,
                organizationRole: {
                  role: item.comment_by_role,
                },
              },
              commentDate: item.commented_at,
              message: item.message,
            }))}
          />
          {isLoading ? (
            <div className="loading-spinner">
              <Spinner type="grow" />
            </div>
          ) : (
            <>
              <Label>New Message</Label>
              <Input
                type="textarea"
                value={message}
                rows={12}
                onChange={(evt) => setMessage(evt.target.value)}
              />
              <div className="text-end gray-medium">{message.length}/1000</div>
              <div className="comments-btn-group">
                <Button
                  className="ps-4 pe-4"
                  color="primary"
                  size="sm"
                  disabled={!message || message.length > 1000}
                  onClick={handleAddComment}
                >
                  Post
                </Button>
              </div>
            </>
          )}
        </TabPane>
        <TabPane tabId="history" className="comments-block">
          <TicketHistory historyDetails={historyDetails} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default TicketChatHistory;
