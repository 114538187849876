import { OrgSelector } from "#Components/AuthorizedOrganizations.jsx";
import { actionsMap } from "#Components/AutoDeployment";
import { cloneAction } from "#Constants/Endpoints.jsx";
import { TICKET_STATES } from "#Constants/TicketFields";
import { submitLogout, submitSwitchOrg } from "#Graphql/mutate";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { Badge } from "reactstrap";
import confirm from "reactstrap-confirm";

const fetchClient = axios.create();

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const phoneRegex =
  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
export const websiteRegex =
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/i;

export const isTicketStateAny = (ticket, stateArr = []) => {
  const { state = "" } = ticket;
  const re = new RegExp(`^(${stateArr.join("|")})$`, "gi");
  return !!(state + "").trim().match(re);
};

export const isSubscriptionStatusAny = (deployment, statusArr = []) => {
  const { subscriptionStatus = "" } = deployment;
  const re = new RegExp(`^(${statusArr.join("|")})$`, "gi");
  return !!(subscriptionStatus + "").trim().match(re);
};

export const renderTicketStatus = (ticket) => {
  if (TICKET_STATES[ticket.state]) {
    return (
      <div className="text-capitalize state" title={ticket.state}>
        <i className={`fa fa-circle small state-${ticket.state}`} />{" "}
        {ticket.state.split("_").join(" ")}
      </div>
    );
  } else {
    return ticket.state;
  }
};

export const getRemainingTime = (expirationTimestamp) => {
  const expireDate = moment.utc(expirationTimestamp);
  const remainingDays = expireDate.diff(moment.utc(), "days");
  const remainingHours = expireDate.diff(moment.utc(), "hours");
  const remainingSeconds = expireDate.diff(moment.utc(), "seconds");
  return {
    remainingDays,
    remainingHours,
    remainingSeconds,
    expireDate: expireDate.local().format("MMM DD, YYYY hh:mm A"),
  };
};

export const isTrialExpired = (deployment) => {
  if (isSubscriptionStatusAny(deployment, ["trial"])) {
    const { remainingHours } = getRemainingTime(deployment.trialExpiresAt);
    return remainingHours <= 0;
  }
  return false;
};

export const getCSPMap = (ticket) => {
  const cspMap = {};
  if (ticket) {
    const cspArr =
      _.get(ticket, "jsonSchema.schema.properties.cluster.oneOf") || [];
    cspArr.forEach((cspItem) => {
      const value = _.get(cspItem, "properties.cloudServiceProvider.default");
      cspMap[value] = cspItem;
    });
  }
  return cspMap;
};

export const getSelectedCSP = (ticket) => {
  if (ticket) {
    const cspMap = getCSPMap(ticket);
    const cspValue = _.get(
      ticket,
      "jsonSchema.formData.cluster.cloudServiceProvider",
    );
    if (cspValue && cspMap[cspValue]) {
      return cspMap[cspValue];
    }
  }
  return {};
};

export const getCapacityMap = (ticket) => {
  const capacityMap = {};
  if (ticket) {
    const selectedCSP = getSelectedCSP(ticket);
    const capacityArr = _.get(selectedCSP, "properties.capacity.oneOf") || [];
    capacityArr.forEach((capacityItem) => {
      const value = _.get(capacityItem, "const");
      capacityMap[value] = capacityItem;
    });
  }
  return capacityMap;
};

export const getSelectedCapacity = (ticket) => {
  if (ticket) {
    const capacityMap = getCapacityMap(ticket);
    const capacityValue = _.get(ticket, "jsonSchema.formData.cluster.capacity");
    if (capacityValue && capacityMap[capacityValue]) {
      return capacityMap[capacityValue];
    }
  }
  return {};
};

export const cloudPlatform = (ticket) => {
  return getSelectedCSP(ticket).title || "";
};

export const capacity = (ticket) => {
  return getSelectedCapacity(ticket).title || "";
};

export const traverse = ({ obj, path = "", action = ({}) => {} }) => {
  if (_.isPlainObject(obj)) {
    Object.keys(obj).forEach((key) => {
      action({ key, path: path ? path + "." + key : key, value: obj[key] });
      traverse({
        obj: obj[key],
        path: path ? path + "." + key : key,
        action,
      });
    });
  }
};

export const downloadContentAsFile = ({
  content = "",
  fileName = "temp.json",
}) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(content),
  );
  element.setAttribute("download", fileName);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const clearHash = () => {
  if (window.location.hash) {
    window.history.replaceState("", document.title, window.location.pathname);
  }
};

export const getUserIcon = (userRoleInOrg = {}) => {
  if (
    userRoleInOrg.isSuperAdmin ||
    ["super_admin"].includes(userRoleInOrg.role)
  ) {
    return "fa fa-user-gear";
  } else if (
    userRoleInOrg.isSalesAgent ||
    ["sales_agent"].includes(userRoleInOrg.role)
  ) {
    return "fa fa-person-rays";
  } else if (userRoleInOrg.isAuthorizedUser) {
    return "fa-regular fa-user-circle";
  } else if (
    userRoleInOrg.isAgent ||
    ["agent_admin", "agent"].includes(userRoleInOrg.role)
  ) {
    return "fa fa-user-tie";
  } else if (
    userRoleInOrg.isClient ||
    ["client_admin", "client_user"].includes(userRoleInOrg.role)
  ) {
    return "fa fa-user-circle";
  } else {
    return "fa fa-person-circle-question";
  }
};

export const logoutUser = async ({ history, alert }) => {
  const { user } = JSON.parse(sessionStorage.getItem("Clouve.object"));
  try {
    sessionStorage.clear();
    const { logout } = await submitLogout();
    if (logout === "SUCCESS") {
      alert.success(
        `${user.firstName} ${user.lastName} logged out successfully!`,
      );
      history.push("/");
    } else {
      alert.error(`${user.firstName} ${user.lastName} failed to logout`);
    }
  } catch (err) {
    alert.error(err.message);
  }
};

export const getUserType = (userRoleInOrg = {}) => {
  if (userRoleInOrg.isSuperAdmin) {
    return "Super Admin";
  } else if (userRoleInOrg.isSalesAgent) {
    return "Sales Agent";
  } else if (userRoleInOrg.isAgentAdmin) {
    return "First Tier Agent";
  } else if (userRoleInOrg.isAgentUser) {
    return "Second Tier Agent";
  } else if (userRoleInOrg.isClientAdmin) {
    return userRoleInOrg.isAuthorizedUser
      ? "Authorized Administrator"
      : "Administrator";
  } else if (userRoleInOrg.isClientUser) {
    return userRoleInOrg.isAuthorizedUser ? "Authorized User" : "User";
  } else {
    return "Unknown User Type";
  }
};

export const switchOrganization = async (authOrg, changePath) => {
  if (authOrg && authOrg.id) {
    const { switchOrg } = await submitSwitchOrg({ orgId: authOrg.id });
    if (switchOrg) {
      const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
      const newClvObj = { ...clvObj, ...switchOrg };
      sessionStorage.setItem("Clouve.object", JSON.stringify(newClvObj));
      if (changePath) {
        location = changePath;
      } else {
        location.reload();
      }
    } else {
      throw new Error(`Failed to switch to ${authOrg.name} organization`);
    }
  } else {
    throw new Error("No authorized organization found");
  }
};

export const camelToTitle = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const confirmDeploymentAction = async ({
  actionTitle,
  actionTarget,
  ticketDetails,
  ticketAction,
  actionConfig = {},
  forceConfirm,
  onProceed = () => {},
  onSuccess = () => {},
  onFailure = () => {},
}) => {
  if (!forceConfirm) {
    const action = actionTitle || ticketAction.split("/").pop();
    const actionLabel = actionTitle || action.split("_").join(" ");
    const confirmProps = {
      title: (
        <>
          Apply{" "}
          <Badge color={actionsMap[action.toLowerCase()]}>
            {actionLabel.toUpperCase()}
          </Badge>{" "}
          Action?
        </>
      ),
      message: (
        <div>
          Are you sure you want to apply{" "}
          <Badge color={actionsMap[action.toLowerCase()]}>
            {actionLabel.toUpperCase()}
          </Badge>{" "}
          action to <strong>{actionTarget || ticketDetails.title}</strong>?
        </div>
      ),
      confirmText: "Proceed",
      confirmColor: actionsMap[action.toLowerCase()] || "primary",
      cancelText: "Cancel",
      cancelColor: "link",
    };
    if (action.match(/^delete$/gi)) {
      confirmProps.confirmText = "DELETE";
      confirmProps.confirmColor = "danger";
    }

    const proceed = await confirm(confirmProps);
    if (!proceed) return;
  }

  onProceed();
  const actionQueryString = new URLSearchParams({
    ticketId: ticketDetails.id,
    ...actionConfig,
  }).toString();
  try {
    const response = await fetchClient.get(
      ticketAction + `?${actionQueryString}`,
    );
    const { code, message, deploymentData, deploymentTicketData } =
      response.data;
    if (code === 200) {
      onSuccess({ deploymentData, deploymentTicketData });
    } else {
      onFailure(new Error(message));
    }
  } catch (error) {
    console.error(error);
    onFailure(error);
    throw error;
  }
};

export const confirmCloneDeploymentAction = async ({
  ticketDetails,
  onProceed,
  onSuccess,
  onFailure,
}) => {
  const { user, org } = JSON.parse(sessionStorage.getItem("Clouve.object"));
  let cloneToOrgId = org.id;
  const getApplyConfig = ({
    forceConfirm = false,
    onProceed,
    onSuccess,
    onFailure,
  }) => {
    return {
      ticketDetails,
      ticketAction: cloneAction,
      actionConfig: org.id === cloneToOrgId ? {} : { cloneToOrgId },
      forceConfirm,
      onProceed,
      onSuccess,
      onFailure,
    };
  };

  if (user.authorizedOrgs && user.authorizedOrgs.length) {
    const proceed = await confirm({
      title: (
        <>
          Apply <Badge color={actionsMap["clone"]}>CLONE</Badge> Action?
        </>
      ),
      message: (
        <>
          <div className="mb-2">
            To which organization would you like to{" "}
            <Badge color={actionsMap["clone"]}>CLONE</Badge>{" "}
            <strong>{ticketDetails.title}</strong>?
          </div>
          <OrgSelector
            orgOptions={[user.organization, ...user.authorizedOrgs]}
            selectedOrgId={cloneToOrgId}
            onSwitchOrg={(authOrg) => (cloneToOrgId = authOrg.id)}
          />
        </>
      ),
      confirmText: "Proceed",
      confirmColor: actionsMap["clone"] || "primary",
      cancelText: "Cancel",
      cancelColor: "link",
    });
    if (proceed) {
      await confirmDeploymentAction(
        getApplyConfig({
          forceConfirm: true,
          ticketDetails,
          onProceed,
          onSuccess,
          onFailure,
        }),
      );
    }
  } else {
    await confirmDeploymentAction(
      getApplyConfig({
        ticketDetails,
        onProceed,
        onSuccess,
        onFailure,
      }),
    );
  }
};
