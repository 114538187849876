import Clone from "#Components/Clone";
import Delete from "#Components/Delete";
import ErrorBoundary from "#Components/ErrorBoundary";
import { InfoColumn, InfoTable } from "#Components/InfoTable";
import { deleteAction } from "#Constants/Endpoints";
import { TICKET_STATES } from "#Constants/TicketFields";
import { fetchDeployments } from "#Graphql/query";
import {
  capacity,
  cloudPlatform,
  confirmCloneDeploymentAction,
  confirmDeploymentAction,
  isSubscriptionStatusAny,
  isTicketStateAny,
  isTrialExpired,
  renderTicketStatus,
} from "#hoc/util";
import withRouter from "#hoc/withRouter";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import { Card } from "reactstrap";

class Deployments extends PureComponent {
  state = {
    deployments: [],
    isLoading: false,
    orgId: null,
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  componentDidMount() {
    this.fetchOrgDeployments();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { org } = JSON.parse(sessionStorage.getItem("Clouve.object"));
    const { location } = this.props;
    if (this.state.orgId !== org.id) {
      this.fetchOrgDeployments();
    }
    if (location?.key !== prevProps.location?.key) {
      this.fetchOrgDeployments();
    }
  }

  fetchOrgDeployments = () => {
    const { user, org, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    this.setState({ isLoading: true, orgId: org.id });
    fetchDeployments()
      .then((response) => {
        this.setState({ isLoading: false });
        if (response instanceof Error) {
          this.errorHandler(response);
        }
        const { getDeploymentsList: deployments } = response;
        if ((deployments && deployments.length) || userRoleInOrg.isClientUser) {
          this.setState({ deployments });
        } else {
          this.props.history.push("/deployment/create");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onRowClicked = ({ orgTicketId }) => {
    this.props.history.push(`/deployment/${orgTicketId}`);
  };

  onCloneDeployment = async (deployment) => {
    await confirmCloneDeploymentAction({
      ticketDetails: deployment.ticket,
      onProceed: () => {
        this.setState({ isLoading: true });
      },
      onSuccess: ({ deploymentTicketData }) => {
        this.setState({ isLoading: false });
        if (deploymentTicketData) {
          this.props.history.push(
            `/deployment/${deploymentTicketData.orgTicketId}`,
          );
        }
      },
      onFailure: this.errorHandler,
    });
  };

  onDeleteDeployment = async (deployment) => {
    const { deployments } = this.state;
    await confirmDeploymentAction({
      ticketDetails: deployment.ticket,
      ticketAction: deleteAction,
      onProceed: () => {
        this.setState({ isLoading: true });
      },
      onSuccess: () => {
        const deleteIdx = deployments.map((e) => e.id).indexOf(deployment.id);
        const deploymentsClone = [...deployments];
        deploymentsClone.splice(deleteIdx, 1);
        this.setState({
          isLoading: false,
          deployments: deploymentsClone,
        });
      },
      onFailure: this.errorHandler,
    });
  };

  render() {
    const { isLoading, deployments } = this.state;

    const renderDeploymentIcon = (deployment, index, column, id) => {
      return (
        <div className="cell-ticket-type deployment" title={deployment.title}>
          <i className="fa fa-server blue" data-tag="allowRowEvents" />
        </div>
      );
    };

    const renderSubscription = (deployment, index, column, id) => {
      if (
        isSubscriptionStatusAny(deployment, ["trial"]) &&
        isTrialExpired(deployment)
      ) {
        return <center>TRIAL EXPIRED</center>;
      }
      return (
        <center>
          {(deployment.subscriptionStatus + "")
            .split("_")
            .join(" ")
            .toUpperCase()}
        </center>
      );
    };

    const renderClone = (deployment, index, column, id) => {
      if (deployment) {
        //enable clone only if subscription is active and ticket is not in progress
        const cloneEnabled =
          isSubscriptionStatusAny(deployment, [
            "pending",
            "active",
            "exempt",
          ]) &&
          !isTicketStateAny(deployment.ticket, [
            TICKET_STATES.inProgress,
            TICKET_STATES.open,
          ]);
        return (
          <Clone
            disabled={!cloneEnabled}
            onClick={() => this.onCloneDeployment(deployment)}
          />
        );
      }
    };

    const renderDelete = (deployment, index, column, id) => {
      if (deployment) {
        //enable delete only if trial is expired or subscription/trial is canceled, and ticket is not in progress
        const deleteEnabled =
          ((isSubscriptionStatusAny(deployment, ["trial"]) &&
            isTrialExpired(deployment)) ||
            isSubscriptionStatusAny(deployment, [
              "exempt",
              "canceled",
              "trial_canceled",
            ])) &&
          !isTicketStateAny(deployment.ticket, [TICKET_STATES.inProgress]);
        return (
          <Delete
            disabled={!deleteEnabled}
            onClick={() => this.onDeleteDeployment(deployment)}
          />
        );
      }
    };

    const data = deployments
      .filter((dpl) => dpl.deploymentAction !== "delete")
      .map((dpl) => ({
        title: dpl.ticket.title,
        orgTicketId: dpl.ticket.orgTicketId,
        subscriptionStatus: `${dpl.subscriptionStatus} ${
          isTrialExpired(dpl) ? "Expired" : ""
        }`,
        csp: cloudPlatform(dpl.ticket),
        capacity: capacity(dpl.ticket),
        ...dpl,
      }));
    return (
      <Card className="content-wrapper">
        <ErrorBoundary error="bg-error">
          <div className="ticket-tbl mb-2">
            <InfoTable
              data={data}
              onRowClicked={this.onRowClicked}
              pagination={data.length > 10}
              highlightOnHover
              pointerOnHover
              progressPending={isLoading}
            >
              <InfoColumn
                selector={(deployment) => deployment.id}
                cell={renderDeploymentIcon}
                width="30px"
                compact
                center
              />
              <InfoColumn selector={(deployment) => deployment.title} sortable>
                App/Bundle Title
              </InfoColumn>
              <InfoColumn
                selector={(deployment) => deployment.ticket.state}
                cell={(deployment) => renderTicketStatus(deployment.ticket)}
                width="150px"
                sortable
              >
                Status
              </InfoColumn>
              <InfoColumn
                selector={(deployment) =>
                  (deployment.subscriptionStatus + "")
                    .split("_")
                    .join(" ")
                    .toUpperCase()
                }
                cell={renderSubscription}
                center
              >
                Subscription
              </InfoColumn>
              <InfoColumn selector={(deployment) => deployment.csp} center>
                Cloud Platform
              </InfoColumn>
              <InfoColumn selector={(deployment) => deployment.capacity} center>
                Capacity
              </InfoColumn>
              <InfoColumn
                width="50px"
                selector={(deployment) => deployment.id}
                cell={renderClone}
                compact
                center
              >
                Clone
              </InfoColumn>
              <InfoColumn
                width="50px"
                selector={(deployment) => deployment.id}
                cell={renderDelete}
                compact
                center
              >
                Delete
              </InfoColumn>
            </InfoTable>
          </div>
        </ErrorBoundary>
      </Card>
    );
  }
}

export default withAlert()(withRouter(Deployments));
