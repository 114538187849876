import { SolutionItem } from "#Components/SolutionItem";
import {
  submitCreateDeployment,
  submitSubscribeDeployment,
} from "#Graphql/mutate";
import { fetchClientPaymentMethod, getClientToken } from "#Graphql/query";
import withRouter from "#hoc/withRouter";
import DropIn from "braintree-web-drop-in-react";
import _ from "lodash";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import { Alert, Button, Card, Col, Row, Spinner } from "reactstrap";

class Checkout extends PureComponent {
  state = {
    solutionItem: {},
    capacity: "x-small",
    planItem: null,
    clientPaymentToken: null,
    isLoading: true,
    currentPlanEndDate: "",
    agreementChecked: false,
    payButtonEnabled: false,
    ticketDetails: null,
  };

  async componentDidMount() {
    const { history, location = {} } = this.props;
    const { ticketDetails, solutionItem } = location.state || {};
    if (!ticketDetails || !solutionItem) {
      history.push("/");
      return;
    }
    const { servicePlans = {} } = solutionItem;
    const { jsonSchema } = ticketDetails;
    const { formData } = jsonSchema;
    const { cluster } = formData;
    const { capacity } = cluster;
    const planItem = servicePlans[capacity];
    try {
      const { clientToken } = await getClientToken();
      if (clientToken) {
        this.setState({
          clientToken,
          isLoading: true,
          ticketDetails,
          solutionItem,
          planItem,
        });
      } else {
        this.errorHandler({ message: "Failed to get client token" });
      }
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { clientToken } = this.state;
    const { ticketDetails, solutionItem } = this.state;
    const { location: prevLocation = {} } = prevProps;
    const { ticketDetails: prevTicketDetails, solutionItem: prevSolutionItem } =
      prevLocation.state || {};
    if (clientToken !== prevState.clientToken) {
      this.setState({ isLoading: true });
      const { getClientPaymentMethod: clientPaymentToken } =
        await fetchClientPaymentMethod();
      this.setState({ isLoading: false });
      if (clientPaymentToken) {
        this.setState({ clientPaymentToken });
      } else {
        this.errorHandler({ message: "Failed to get client payment token" });
      }
    }
    if (
      ticketDetails !== prevTicketDetails ||
      solutionItem !== prevSolutionItem
    ) {
      const { servicePlans = {} } = solutionItem;
      const { jsonSchema } = ticketDetails;
      const { formData } = jsonSchema;
      const { cluster } = formData;
      const { capacity } = cluster;
      const planItem = servicePlans[capacity];
      this.setState({ ticketDetails, solutionItem, planItem });
    }
  }

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  checkoutSubmit = async () => {
    const { createDeployment, subscribeDeployment } = this.props;
    const { ticketDetails, planItem } = this.state;
    if (!ticketDetails) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      const { nonce: paymentMethodNonce } =
        await this.instance.requestPaymentMethod();

      if (ticketDetails.deployment) {
        const { subscribeDeployment } = await submitSubscribeDeployment({
          paymentMethodNonce,
          planId: planItem.id,
          jsonSchema: ticketDetails.jsonSchema,
          deploymentId: ticketDetails.deployment.id,
        });
        if (subscribeDeployment && subscribeDeployment.code === 200) {
          this.showSuccessMessage(subscribeDeployment.message);
          if (subscribeDeployment.deploymentTicketData) {
            this.deploymentCheckoutSuccess(
              subscribeDeployment.deploymentTicketData,
            );
          }
        } else {
          this.errorHandler(subscribeDeployment);
        }
      } else {
        const { createDeployment } = await submitCreateDeployment({
          paymentMethodNonce,
          deploymentData: ticketDetails,
        });
        if (createDeployment && createDeployment.code === 200) {
          const { message, deploymentTicketData } = createDeployment;
          this.showSuccessMessage(message);
          if (deploymentTicketData) {
            this.props.history.push(
              `/deployment/${deploymentTicketData.orgTicketId}`,
            );
          }
        } else {
          this.errorHandler(createDeployment);
        }
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  onBrainTreeDropInInstance = (instance) => {
    this.instance = instance;
    if (this.instance.isPaymentMethodRequestable()) {
      this.setState({ payButtonEnabled: true });
    }
  };

  onNoPaymentMethodRequestable = () => {
    this.setState({ payButtonEnabled: false });
  };

  onPaymentMethodRequestable = (payload) => {
    if (this.instance.isPaymentMethodRequestable()) {
      this.setState({ payButtonEnabled: true });
    }
  };

  onPaymentOptionSelected = (payload) => {
    if (this.instance.isPaymentMethodRequestable()) {
      this.setState({ payButtonEnabled: true });
    }
  };

  toggleAgreementCheckbox = (agreementChecked) => {
    this.setState({ agreementChecked });
  };

  deploymentCheckoutSuccess = (deploymentTicketData) => {
    const { history } = this.props;
    history.push(`/checkout/success#/${deploymentTicketData.orgTicketId}`);
  };

  handleCapacitySelect = (capacity) => {
    const { ticketDetails } = this.state;
    if (ticketDetails) {
      _.set(
        ticketDetails,
        "jsonSchema.formData.cluster.capacity",
        capacity || "x-small",
      );
      this.setState({ ticketDetails: { ...ticketDetails } });
    }
  };

  renderPaymentForm() {
    const { ticketDetails } = this.state;
    if (!ticketDetails) {
      return;
    }

    const { clientPaymentToken, isLoading } = this.state;
    const options = {
      authorization: clientPaymentToken,
      paymentOptionPriority: ["card", "paypal"],
      paypal: { flow: "vault", amount: "", currency: "USD" },
      defaultFirst: true,
      cardPaymentMethodPayload: { details: "cardType" },
    };
    return (
      <div className="checkout-cart p-3">
        {isLoading && (
          <div className="loading-spinner">
            <Spinner type="grow" />
          </div>
        )}
        <DropIn
          options={options}
          onPaymentMethodRequestable={this.onPaymentMethodRequestable}
          onNoPaymentMethodRequestable={this.onNoPaymentMethodRequestable}
          onPaymentOptionSelected={this.onPaymentOptionSelected}
          onInstance={this.onBrainTreeDropInInstance}
        />
        <Alert color="warning text-center mt-4">
          You can cancel subscription at any time to stop the monthly payments.
        </Alert>
      </div>
    );
  }

  onCancel = () => {
    const { ticketDetails } = this.state;
    if (ticketDetails.orgTicketId) {
      this.props.history.push(`/deployment/${ticketDetails.orgTicketId}`);
    } else {
      this.props.history.push("/deployments");
    }
  };

  render() {
    const {
      ticketDetails,
      solutionItem,
      agreementChecked,
      payButtonEnabled,
      isLoading,
      clientPaymentToken,
    } = this.state;
    if (!ticketDetails) {
      return;
    }
    const { jsonSchema } = ticketDetails;
    const { formData } = jsonSchema;
    const { cluster } = formData;
    const { capacity } = cluster;
    return (
      <Card className="content-wrapper create-ticket-wrapper pb-5">
        <div className="payment-checkout">
          <Row>
            <Col>
              <h5 className="pt-4 pb-4">Cart</h5>
              <SolutionItem
                solutionItem={solutionItem}
                selectedCapacity={capacity}
                onCapacitySelect={this.handleCapacitySelect}
                selected
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="pt-4 pb-4">Payment Method</h5>
              {clientPaymentToken ? (
                this.renderPaymentForm()
              ) : (
                <div className="loading-spinner">
                  <Spinner type="grow" />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className={isLoading ? "invisible" : ""}>
              <p className="agreement-checkbox">
                <input
                  type="checkbox"
                  checked={agreementChecked}
                  onChange={(evt) =>
                    this.toggleAgreementCheckbox(evt.target.checked)
                  }
                />
                <label
                  onClick={() =>
                    this.toggleAgreementCheckbox(!agreementChecked)
                  }
                >
                  I have read and agree to the
                </label>
                <a
                  href={`/thermo/agreement`}
                  target="_blank"
                  className="text-nowrap"
                >
                  Service Agreement
                </a>
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col sm={6}>
              <Button
                block
                id="submitButton"
                color="primary"
                onClick={this.checkoutSubmit}
                disabled={!agreementChecked || !payButtonEnabled}
              >
                {ticketDetails?.deployment
                  ? "SUBSCRIBE"
                  : "SUBSCRIBE & INSTALL"}
              </Button>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center pb-5">
            <Col sm={3}>
              <Button
                block
                outline
                size="sm"
                color="danger"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Card>
    );
  }
}

export default withAlert()(withRouter(Checkout));
