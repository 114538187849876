import React, { PureComponent } from "react";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

export class TabbedPane extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = {
      activeTab: "",
      openTab: "",
      tabButtons: [],
      tabComponents: [],
    };
  }

  componentDidMount() {
    this.activateTab();
  }

  componentDidUpdate(prevProps, prevState) {
    const { onTabChange = (tabId) => {} } = this.props;
    if (prevProps.defaultTab !== this.props.defaultTab) {
      this.setState({ activeTab: this.getActiveTab() });
    }
    if (prevProps.activeTab !== this.props.activeTab) {
      this.handleTabClick(this.props.activeTab);
    }
    if (prevState.activeTab !== this.state.activeTab) {
      if (this.state.activeTab && this.state.activeTab.match(/^\/.+/gi)) {
        onTabChange(this.state.activeTab);
      }
    }
  }

  getActiveTab() {
    const { tabs = [], defaultTab, isVisible = (tabId) => true } = this.props;
    const visibleTabs = tabs.filter(({ tabId }) => isVisible(tabId));
    if (visibleTabs.length) {
      return defaultTab || visibleTabs[0].tabId;
    }
  }

  activateTab(tabId) {
    this.setState({ activeTab: tabId || this.getActiveTab() });
  }

  handleTabClick = (tabId) => {
    if (!tabId || tabId === "/") {
      return;
    }
    if (this.state.activeTab !== tabId) {
      this.activateTab(tabId);
    }
  };

  toggleTabOpen = (tabId) => {
    const { openTab } = this.state;
    if (openTab === tabId) {
      tabId = "";
    }
    this.setState({
      openTab: tabId,
    });
  };

  renderTabIcon(tab) {
    if (typeof tab.tabIcon === "function") {
      return tab.tabIcon();
    }
    return tab.tabIcon ? <i className={`${tab.tabIcon}`} /> : null;
  }

  renderTabButton(tab, disabled) {
    if (tab.hideLabel) {
      return null;
    }
    const { activeTab, openTab } = this.state;
    if (tab.tabComponents) {
      const isActive = tab.tabComponents.some(
        ({ tabId }) => tabId === activeTab,
      );
      return (
        <Dropdown
          nav
          key={tab.tabId}
          isOpen={openTab === tab.tabId}
          toggle={() => this.toggleTabOpen(tab.tabId)}
        >
          <DropdownToggle nav caret className={isActive ? "active" : ""}>
            {tab.tabLabel}
          </DropdownToggle>
          <DropdownMenu>
            {tab.tabComponents.map((item, idx) => (
              <DropdownItem
                key={idx}
                onClick={(evt) => {
                  evt.preventDefault();
                  evt.stopPropagation();
                  this.handleTabClick(item.tabId);
                }}
                active={activeTab === item.tabId}
                className="small blue"
              >
                {item.tabLabel}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      );
    } else {
      return (
        <NavItem key={tab.tabId}>
          <NavLink
            className={activeTab === tab.tabId ? "active" : ""}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              this.handleTabClick(tab.tabId);
            }}
            disabled={disabled}
          >
            {tab.tabIconAfterLabel ? (
              <>
                {tab.tabLabel} {this.renderTabIcon(tab)}
              </>
            ) : (
              <>
                {this.renderTabIcon(tab)} {tab.tabLabel}
              </>
            )}
          </NavLink>
        </NavItem>
      );
    }
  }

  renderTabComponent(tab) {
    return (
      <TabPane key={tab.tabId} tabId={tab.tabId}>
        <Row>
          <Col>{tab.tabComponent}</Col>
        </Row>
      </TabPane>
    );
  }

  renderTabComponents(tabComponents = []) {
    const tabPanes = [];
    tabComponents.forEach((tabComponent) => {
      if (tabComponent.tabComponents) {
        tabPanes.push(...this.renderTabComponents(tabComponent.tabComponents));
      } else {
        tabPanes.push(this.renderTabComponent(tabComponent));
      }
    });
    return tabPanes;
  }

  render() {
    const {
      tabs = [],
      isVisible = (tabId) => true,
      width,
      justifiedTabs = true,
      showSingleTab = false,
      disabled,
      className = "",
    } = this.props;
    const { activeTab } = this.state;
    const visibleTabs = tabs.filter(({ tabId }) => isVisible(tabId));
    const classNames = [];
    showSingleTab || (visibleTabs.length < 2 && classNames.push("no-border"));
    width && classNames.push(`${width}-width`);
    return (
      <div className={`tab-nav ${className}`}>
        {showSingleTab || visibleTabs.length > 1 ? (
          <Nav tabs justified={justifiedTabs}>
            {visibleTabs.map((tab) => this.renderTabButton(tab, disabled))}
          </Nav>
        ) : null}
        <TabContent activeTab={activeTab} className={classNames.join(" ")}>
          {this.renderTabComponents(visibleTabs)}
        </TabContent>
      </div>
    );
  }
}

export default class TabNav extends TabbedPane {
  constructor(...args) {
    super(...args);
  }

  componentDidMount() {
    window.addEventListener(
      "hashchange",
      () => {
        this.activateTab();
      },
      false,
    );
    super.componentDidMount();
  }

  componentWillUnmount() {
    window.removeEventListener(
      "hashchange",
      () => {
        this.activateTab();
      },
      false,
    );
  }

  getTabIds() {
    const { tabs = [], isVisible = (tabId) => true } = this.props;
    const visibleTabs = tabs.filter(({ tabId }) => isVisible(tabId));
    const tabIds = [];
    visibleTabs.forEach(({ tabId, tabComponents = [] }) => {
      tabIds.push(tabId);
      tabComponents.forEach((tabComp) => tabIds.push(tabComp.tabId));
    });
    return tabIds;
  }

  isAnyTabActive() {
    const { hash } = window.location;
    if (hash) {
      const anyTabRe = new RegExp(`(${this.getTabIds().join("|")})`, "gi");
      return hash.match(anyTabRe);
    }
  }

  getActiveTab() {
    const { hash } = window.location;
    if (hash) {
      const tabIds = this.getTabIds();
      const activeTabIds = tabIds.filter((tabId) =>
        hash.match(new RegExp(tabId, "gi")),
      );
      if (activeTabIds.length) {
        return activeTabIds[0];
      }
    }
    return super.getActiveTab();
  }

  handleTabClick = (tabId) => {
    if (!tabId || tabId === "/") {
      return;
    }
    if (this.state.activeTab !== tabId) {
      const { location } = window;
      const currHash = location.hash;
      let newHash = currHash;
      if (location.hash) {
        const isAnyTabActive = this.isAnyTabActive();
        if (isAnyTabActive) {
          const activeTabId = this.getActiveTab();
          newHash = currHash.replace(activeTabId, tabId);
        } else {
          newHash += tabId;
        }
      } else {
        newHash = tabId;
      }
      if (newHash && newHash !== currHash) {
        location.hash = newHash;
      }
    }
  };
}
